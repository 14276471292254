
import { Component, Vue } from 'vue-property-decorator'
import { TypeList } from '@/types/ecologyMonitor.d'
import * as echarts from 'echarts'

@Component({
  name: 'History'
})
export default class SoilHistory extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 29 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  private loading = false
  private dataList: any = []
  private chartList: any = []
  page = 1
  size = 10
  total = 0

  get deviceNumber () {
    return this.$route.params.deviceNumber as string
  }

  private echart: any
  private echartSelect = '0'
  private echartData: any = {
    pressure: [],
    flow: [],
    dayLacc: [],
    collectTime: []
  }

  private typeList: Array<TypeList> = []
  private finds: Array<string> = []

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    this.getData()
    this.selectWaterLine()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  initTime () {
    const val = this.$dayjs().format('YYYY-MM-DD')
    const vals = this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD')
    this.searchInfo.dateRange = [vals, val]
  }

  getData () {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.loading = true
      this.$axios.get(this.$apis.smartHouse.selectSqWaterMeterDataByPage, {
        deviceNumber: this.deviceNumber,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1],
        notDesc: '1',
        page: this.page,
        size: this.size
      }).then((res) => {
        this.total = res.waterMeter.total || 0
        this.dataList = res.waterMeter.list || []
        this.finds = res.finds
      }).finally(() => {
        this.loading = false
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  // 图表总数据
  getChartList () {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.$axios.get(this.$apis.smartHouse.selectSqWaterMeterDataByPage, {
        deviceNumber: this.deviceNumber,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res) => {
        this.chartList = res.waterMeter.list || []
        this.getEchartsData()
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  // 每日用水折线图
  selectWaterLine () {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.$axios.get(this.$apis.smartHouse.selectWaterLine, {
        deviceNumber: this.deviceNumber,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res) => {
        this.chartList = res || []
        this.getEchartsData()
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  onSearch () {
    this.page = 1
    this.getData()
    // 今日用水
    if (this.echartSelect === '0') {
      this.selectWaterLine()
    } else {
      this.getChartList()
    }
  }

  // 图表数据
  getEchartsData () {
    this.echartData = {
      wmLacc: [],
      flow: [],
      pressure: [],
      deviceNumber: '',
      collectTime: []
    }
    // 水
    if (this.echartSelect === '0') {
      this.echartData.dayLacc = this.chartList.dayLacc || []
      this.echartData.collectTime = this.chartList.date || []
    } else {
      if (this.chartList.length > 0) {
        this.chartList.forEach((item: any) => {
          this.echartData.deviceNumber = item.deviceNumber
          this.echartData.pressure.push(item.pressure)
          this.echartData.collectTime.push(item.collectTime)
        })
      }
    }
    this.typeList = [
      {
        legendData: '用水量',
        color: 'rgba(255, 193, 23, ',
        data: this.echartData.dayLacc,
        unit: 'm³',
        value: '0',
        label: '用水量曲线图',
        type: 'dayLacc'
      },
      {
        legendData: '水压',
        color: 'rgba(18, 194, 194, ',
        data: this.echartData.pressure,
        unit: 'Mpa',
        value: '1',
        label: '水压曲线图',
        type: 'pressure'
      }
    ]
    console.log(this.typeList)
    this.draw(Number(this.echartSelect))
  }

  // 折线图
  draw (type: number) {
    this.$nextTick(() => {
      const series: any = []
      const params = this.typeList[type]
      console.log(params)
      series.push({
        name: params.legendData,
        type: 'line',
        symbolSize: 10,
        yAxisIndex: 0,
        smooth: true,
        lineStyle: {
          width: 4,
          shadowBlur: 0,
          shadowColor: `${params.color}0.2)`,
          shadowOffsetY: 20
        },
        itemStyle: {
          normal: {
            borderWidth: 4,
            borderColor: `${params.color}1)`
          }
        },
        data: params.data
      })
      this.echart = echarts.init(this.$refs.echart)
      const option: any = {
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: 12
          }
        },
        grid: {
          top: 50,
          left: 30,
          right: 10,
          bottom: 30,
          containLabel: true
        },
        backgroundColor: 'transparent',
        legend: {
          icon: 'rect',
          itemWidth: 12,
          itemHeight: 1,
          orient: 'horizontal',
          align: 'left',
          top: 0,
          left: 0,
          itemGap: 30,
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: '#8c8c8c',
            lineHeight: 14
          }
        },
        color: `${params.color}1)`,
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          data: this.echartData.collectTime
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: `{value}${params.unit}`
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        }],
        dataZoom: {
          type: 'slider',
          startValue: 0,
          endValue: 30,
          show: this.echartData.collectTime.length > 30,
          borderColor: 'transparent',
          borderCap: 'round',
          height: 10,
          left: 20,
          right: 20,
          bottom: 15,
          showDetail: false
        },
        series: series
      }
      this.echart.setOption(option, true)
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }

  changeEchart () {
    // 今日用水
    if (this.echartSelect === '0') {
      this.selectWaterLine()
    } else {
      this.getChartList()
    }
  }
}
